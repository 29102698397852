import * as React from 'react'
import { Link } from 'gatsby'
import { useState, useEffect } from 'react'

import Layout from '/src/components/layout'
import { progressbar, active } from '/src/styles/pris.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faCircle, faCircleCheck, faCirclePlus, faPuzzlePiece, faRankingStar, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

const PrisPage = () => {

    const [ monthlyCostStandard, setMonthlyCostStandard ] = useState('?')
    const [ monthlyCostPremium, setMonthlyCostPremium ] = useState('?')

    function numberOfApartmentsChange(evt) {
        calculatePrice(evt.target.value)
    }

    function calculatePrice(val) {
		const pricePerApartmentsStandard = 3;
		const pricePerApartmentsPremium = 5;
		const numberOfApartments = parseInt(val);

        if(Number.isInteger(numberOfApartments)) {
            var tmpPriceStandard = numberOfApartments * pricePerApartmentsStandard;
            var tmpPricePremium = numberOfApartments * pricePerApartmentsPremium;

			if(tmpPriceStandard < 99) {
				tmpPriceStandard = 99;
			}
			else if(tmpPriceStandard > 349) {
				tmpPriceStandard = 349;
			}

			if(tmpPricePremium < 199) {
				tmpPricePremium = 199;
			}
			else if(tmpPricePremium > 499) {
				tmpPricePremium = 499;
			}

            setMonthlyCostStandard(parseInt(tmpPriceStandard));
            setMonthlyCostPremium(parseInt(tmpPricePremium));
        }
    }

	return (
		<Layout pageTitle="Prisvärt & tryggt" showBox={false}>
			
			<div className="pageRows">

				<div>
					<div>
						<div className="rowIcon">
							<p style={{ fontSize: '16px' }}>från</p>
							<p style={{ fontSize: '102px', lineHeight: 'normal' }}>3 kr</p>
						</div>
						<div className="rowText">
							<h2>Enkel prismodell</h2>

							<p>Med Brfifys tydliga prismodell är det enkelt att i förväg veta vad kostnaden blir. De två abonnemangen, Standard och Premium, har båda pris per lägenhet och månad med en minsta och högsta månadskostnad. Privata fastighetsägare får pris genom <Link to="/skaffa">offert</Link>.</p>

							{ /*
							<p>Brfify har en enkel och tydlig prismodell så att det är lätt att i förväg veta vad kostnaden blir. Månadskostnaden är <span className="highlightedText">3 kronor/lägenhet</span> exklusive moms, <span style={{whiteSpace: 'nowrap'}}>minst 99 kronor</span> och för brf:er <span className="highlightedText">max 299 kronor</span> samt faktureras månadsvis i efterskott.</p>
							*/}


							<table style={{ textAlign: 'right' }}>
								<tr>
									<td colSpan="2" style={{ textAlign: 'right' }}>Månadskostnad:</td>
									<td style={{ color: 'gray' }}>Minst:</td>
									<td style={{ color: 'gray' }}>Max:</td>
								</tr>
								<tr>
									<td><span style={{whiteSpace: 'nowrap'}}><FontAwesomeIcon icon={faCircle} className="checkGreen" /> Standard</span></td>
									<td>3 kr/lgh</td>
									<td style={{ color: 'gray' }}>99 kr</td>
									<td style={{ color: 'gray' }}>349 kr</td>
								</tr>
								<tr>
									<td><span style={{whiteSpace: 'nowrap'}}><FontAwesomeIcon icon={faCircle} className="checkGold" /> Premium</span></td>
									<td>5 kr/lgh</td>
									<td style={{ color: 'gray' }}>199 kr</td>
									<td style={{ color: 'gray' }}>499 kr</td>
								</tr>
							</table>

							<p><br/></p>

							<p><span className="highlightedText">Första månaden är gratis</span> så att ni i lugn och ro kan komma igång med Brfify.</p>

							<p><br/></p>

							<Link to="/skaffa" className="inlineButton big">Skaffa Brfify <FontAwesomeIcon icon={faAngleRight} /></Link>
						</div>
					</div>
				</div>

				<div>
					<div className="halfs">
						<div className="rowIcon">
							<p style={{ fontSize: '102px', lineHeight: 'normal' }}>{monthlyCostStandard} <span style={{fontSize: '50%'}}>kr/mån</span></p>
							<p style={{ textAlign: 'center', color: 'gray' }}>eller</p>
							<p style={{ fontSize: '102px', lineHeight: 'normal' }}>{monthlyCostPremium} <span style={{fontSize: '50%'}}>kr/mån</span></p>
							<p style={{ textAlign: 'center', color: 'gray', marginTop: '-10px' }}>exkl. moms för hela föreningen</p>
						</div>
						<div className="rowText">
							<h2>Vad blir kostnaden?</h2>

							<p>Ange antal lägenheter i din bostadsrättsföreing så visas ert pris automatiskt. Hyresvärdar är välkommna ett <Link to="/skaffa">erhålla offert</Link>.</p>

							<input type="number" placeholder="Antal lgh?" onChange={numberOfApartmentsChange} className="inputField" style={{width: '100px', minWidth: '100px', fontSize: '16px'}} />
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faThumbsUp} />
						</div>
						<div className="rowText">
							<h2>Schyssta villkor</h2>

							<p>Målet är att föreningar/fastighetsägare stannar kvar för att de vill, inte för att de måste. Därför har Brfify mer av inget!</p>

							<p>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Inga dolda avgifter<br/>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Ingen startavgift<br/>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Ingen uppsägningsavgift<br/>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Ingen uppsägningstid<br/>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Ingen bindingstid<br/>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Betala i efterhand<br/>
								<FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /> Första månaden gratis
							</p>

							{ /* <Link to="/om/villkor" className="inlineButton big">Kompletta villkor <FontAwesomeIcon icon={faAngleRight} /></Link> */ }
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className="rowIcon">
						</div>
						<div className="rowText">
							<h2>Kom igång snabbt &amp; enkelt</h2>

							<p>Att komma igång med Brfify går snabbt och enkelt, bland annat för att alla delar är byggda med användaren i fokus men också för <Link to="/support">support-sidorna</Link> och de hjälp-texter som finns i admin-verktyget.</p>

							<ul className={progressbar}>
								<li className={active}>Intresse</li>
								<li>Avtal</li>
								<li>Intro</li>
								<li>Lansering</li>
							</ul>

							<p><span className="highlightedText">Intresse</span> anmäler du enkelt genom att klicka <Link to="/skaffa">här</Link>.</p>
							<p><span className="highlightedText">Avtal</span> tecknas enkelt helt digitalt med BankID.</p>
							<p><span className="highlightedText">Intro</span> och utbildning finns genom hjälptexter i alla plattformar, självstudier och lärarledd utbildning.</p>
							<p><span className="highlightedText">Lansering</span> av webbsida och app sker när ni så vill.</p>

							<Link to="/skaffa" className="inlineButton big">Skaffa Brfify <FontAwesomeIcon icon={faAngleRight} /></Link>
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faRankingStar} />
						</div>
						<div className="rowText">
							<h2>Prisvärt</h2>

							<p>Brfify har en konkurrenskraftig prissättning och en mängd funktioner som tål att jämföras! Oftast har Brfify fler funktioner och lägre pris än andra jämförbara plattformar.</p>
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faCirclePlus} />
						</div>
						<div className="rowText">
							<h2>Transaktionskostnader</h2>

							<p>Vissa av de <Link to="/funktioner">funktioner</Link> som ingår i Brfify har transaktionskostnader för att användas. Ni väljer själva om funktionerna ska användas och hur mycket vilket gör att ni har kontroll över kostnaderna.</p>

							<table>
								<thead>
									<tr>
										<th>Funktion:</th>
										<th style={{width: 'auto', textAlign: 'left'}}>Pris:</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>BankID-inloggning (legitimering)</td>
										<td style={{width: 'auto', textAlign: 'left'}}>Ingår i Premium.</td>
									</tr>
									<tr>
										<td><Link to="/funktioner#signering">Signering</Link></td>
										<td style={{width: 'auto', textAlign: 'left'}}><i>Presenteras vid lansering.</i></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>


				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faPuzzlePiece} />
						</div>
						<div className="rowText">
							<h2>Extratjänster</h2>

							<p>Utöver de <Link to="/funktioner">funktioner</Link> som ingår i Brfify kanske ni har behov av några extratjänster?</p>

							<table>
								<thead>
									<tr>
										<th>Tjänst:</th>
										<th style={{width: 'auto', textAlign: 'left'}}>Pris:</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Extra lagring i <Link to="/funktioner#bibliotek">Bibliotek</Link></td>
										<td style={{width: 'auto', textAlign: 'left'}}>10 kr/GB/mån</td>
									</tr>
									<tr>
										<td>Utbildning: videomöte kväll/helg</td>
										<td style={{width: 'auto', textAlign: 'left'}}>1 000 kr</td>
									</tr>
									<tr>
										<td>Utbildning: på plats</td>
										<td style={{width: 'auto', textAlign: 'left'}}><i>Offert</i></td>
									</tr>
								</tbody>
							</table>

							<br/><br/><br/>

							<h3>Via partners</h3>
							<p>Utöver själva Brfify-plattformen kan även kringtjänster erbjudas via partners.</p>
							<table>
								<thead>
									<tr>
										<th>Tjänst:</th>
										<th style={{width: 'auto', textAlign: 'left'}}>Pris:</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Domännamn .SE</td>
										<td style={{width: 'auto', textAlign: 'left'}}>219 kr/år + startavgift: 350 kr</td>
									</tr>
									<tr>
										<td>Domännamn .NU</td>
										<td style={{width: 'auto', textAlign: 'left'}}>199 kr/år + startavgift: 350 kr</td>
									</tr>
									<tr>
										<td>Domännamn .COM</td>
										<td style={{width: 'auto', textAlign: 'left'}}>259 kr/år + startavgift: 350 kr</td>
									</tr>
									<tr>
										<td>E-postkonto</td>
										<td style={{width: 'auto', textAlign: 'left'}}>15 kr/GB/mån</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

			</div>

		</Layout>
	)
}

export default PrisPage